<template>
  <div class="buttons-stripe">
    <h2
      class="title-xxs text-style-uppercase"
      v-html="$t('general.download-overlay-title')"
    />

    <div class="buttons-stripe__wrap">
      <div
        class="buttons-stripe__button"
        v-for="event in props.events"
        :key="event.id"
      >
        <nuxt-link
          :to="event.file?.url || event.link"
          class="button-round-1 --violet"
        >
          <span>{{ event.label }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            aria-labelledby="downloadIconTitle"
            stroke="#2329D6"
            stroke-width="1.5"
            stroke-linecap="round"
            fill="none"
            color="#2329D6"
          >
            <path d="M12 3v13M7 12l5 5 5-5M20 21H4" />
          </svg>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  events: Array,
})

const { locale } = useI18n()
const monthName = ref(null)

onMounted(() => {
  const date = new Date()
  const formatter = new Intl.DateTimeFormat(locale.value, { month: 'long' })
  monthName.value = formatter.format(date)
})
</script>

<style lang="scss">
@import './style.scss';
</style>
